// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-branding-js": () => import("./../../../src/pages/Branding.js" /* webpackChunkName: "component---src-pages-branding-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/Data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-js": () => import("./../../../src/pages/Mobile.js" /* webpackChunkName: "component---src-pages-mobile-js" */),
  "component---src-pages-seo-js": () => import("./../../../src/pages/Seo.js" /* webpackChunkName: "component---src-pages-seo-js" */),
  "component---src-pages-social-js": () => import("./../../../src/pages/Social.js" /* webpackChunkName: "component---src-pages-social-js" */),
  "component---src-pages-web-dev-js": () => import("./../../../src/pages/WebDev.js" /* webpackChunkName: "component---src-pages-web-dev-js" */)
}

