import React, {useContext, useReducer} from 'react'
import NavigationContext from './NavigationContext'
import NavigationReducer from './NavigationReducer'

const NavigationState = (props) => {

    const initialState = {
        isMenuShowing: false,
    }



    const [state, dispatch] = useReducer(NavigationReducer, initialState)

    const handleSetIsMenuShowing = (value) => {
        dispatch({type: 'TOGGLE_MENU', payload: value})
    }

    return (
        <NavigationContext.Provider
        value={{
            isMenuShowing: state.isMenuShowing,
            handleSetIsMenuShowing 
        }}
        >
            {props.children}
        </NavigationContext.Provider>
    )
}

export default NavigationState
