const { StaticQuery } = require("gatsby")

const NavigationReducer = (state, action) => {
    switch(action.type) {
        case 'TOGGLE_MENU':
            return({...state, isMenuShowing: action.payload})

    }
}

export default NavigationReducer